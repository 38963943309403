import globalEn from '@/_common/locale/en-US';
import baseEn from '@/app/base/_common/locale/en-US';
import gatewayEn from '@/app/gateway/_common/locale/en-US';
import serviceEn from '@/app/service/_common/locale/en-US';
import monitorEn from '@/app/easeMonitor/_common/locale/en-US';
import stackEn from '@/app/easeStack/_common/locale/en-US';
import homeEn from '@/app/home/_common/locale/en-US';
import middlewareEn from '@/app/easeMiddleware/_common/locale/en-US';
import loadEn from '@/app/easeLoad/_common/locale/en-US';
import tagsEn from '@/app/tags/_common/locale/en-US';
import tenantEn from '@/app/tenant/_common/locale/en-US';
import settingsEn from '@/app/settings/_common/locale/en-US';
import operationEn from '@/app/operation/_common/locale/en-US';
import cicdEn from '@/app/ease-cicd/_common/locale/en-US';
const appEn = {
  'app.resource.loading': 'Loading application static resources...',
  'app.page.resource.loading': 'Loading page static resources...',
  //Top Level Menu
  'app.topmenu.overview': 'Overview',
  'app.topmenu.traffic': 'Traffic',
  'app.topmenu.service': 'Service',
  'app.topmenu.monitor': 'Monitor',
  'app.topmenu.stack': 'Stack',
  'app.topmenu.easeMiddleware': 'Middleware',
  'app.topmenu.easeLoad': 'Load',
  'app.topmenu.settings': 'Settings',
  Operations: 'Operations',
  'app.topmenu.tags': 'Tags',

  //Second Level Menu
  'app.secondmenu.overall': 'Overall',
  'app.secondmenu.topology': 'Topology',
  'app.secondmenu.topn': 'Top-N',
  'app.secondmenu.monitor': 'Monitor',
  'app.secondmenu.management': 'Management',
  'app.secondmenu.mesh': 'Mesh',
  'app.secondmenu.events': 'Events',
  'app.secondmenu.report': 'Report',
  'app.secondmenu.APIDocument': 'API Document',
  'app.secondmenu.tracing': 'Tracing',
  'app.secondmenu.middleware': 'Middleware',
  'app.secondmenu.resource': 'Resource',
  'app.secondmenu.dashboard': 'Dashboard',
  'app.secondmenu.alert': 'Alert',
  'app.secondmenu.log': 'Log',
  'app.secondmenu.deployment': 'Deployment',
  'app.secondmenu.function': 'Function',
  'app.secondmenu.permission': 'Permission',
  'app.secondmenu.auditLog': 'Audit Log',
  'app.secondmenu.notification': 'Notification',
  'app.secondmenu.history': 'History',
  'app.secondmenu.gpu': 'GPU',
  'app.secondmenu.vendor': 'Vendor',
  'app.secondmenu.spec': 'Spec',
  'app.secondmenu.stock': 'Stock',
  'app.secondmenu.Storage': 'Storage',
  'app.secondmenu.purchase': 'Purchase',
  'app.secondmenu.purchased': 'My Resources',
  'app.secondmenu.gpustorage': 'GPU Storage',
  'app.secondmenu.billing': 'Billing',
  'app.secondmenu.usageRecord': 'Usage Record',
  'app.secondmenu.gpu.resources': 'GPU Resources',

  'app.secondmenu.AlertRule': 'Alert Rule',

  'app.secondmenu.kubernetes': 'Kubernetes',
  'app.secondmenu.application': 'Application',
  'app.secondmenu.LOG': 'LOG',
  'app.secondmenu.Operation': 'Operation',
  'app.secondmenu.APMAgents': 'APM Agents',
  'app.secondmenu.Console': 'Console',
  'app.secondmenu.stack.manifest': 'Manifest',
  'app.secondmenu.stack.dashboard': 'Dashboard',
  'app.secondmenu.stack.history.version': 'Deployment',
  'app.secondmenu.stack.event': 'Event',

  'app.secondmenu.easeMiddleware': 'Middleware',
  'app.secondmenu.easeMiddleware.instance': 'Instance',
  'app.secondmenu.easeMiddleware.create.instance': 'Create Instance',
  'app.secondmenu.easeMiddleware.create.instance.host': 'Select Server',
  'app.secondmenu.easeMiddleware.update.instance': 'Service BalanceDetail',
  'app.secondmenu.easeMiddleware.app.detail': 'App BalanceDetail',
  'app.secondmenu.easeMiddleware.host': 'Server',
  'app.secondmenu.easeMiddleware.create.host': 'Add Server',
  'app.secondmenu.easeMiddleware.server': 'Server',
  'app.secondmenu.easeMiddleware.update.host': 'Update Server',
  'app.secondmenu.easeMiddleware.detail.host': 'Host BalanceDetail',
  'app.secondmenu.easeMiddleware.detail.host.AlertRuleForm': 'Alert Rule Form',
  'app.secondmenu.easeMiddleware.host.tags': 'Add Tags',
  'app.secondmenu.easeMiddleware.service': 'Service',
  'app.secondmenu.easeMiddleware.create.service': 'Create Service',
  'app.secondmenu.easeMiddleware.service.version': 'Version',
  'app.secondmenu.easeMiddleware.service.template': 'Config Template',
  'app.secondmenu.easeMiddleware.service.spec': 'Service Spec',
  'app.secondmenu.easeMiddleware.create.service.spec': 'Create Service Spec',
  'app.secondmenu.easeMiddleware.update.service.spec': 'Update Service Spec',
  'app.secondmenu.easeMiddleware.tags': 'Tags',
  'app.secondmenu.easeMiddleware.tags.resources': 'Resources',
  'app.secondmenu.easeMiddleware.tags.tag.resources': 'Tag Resources',
  'app.secondmenu.easeMiddleware.jumpServer': 'Jump Server',
  'app.secondmenu.easeMiddleware.jumpServers': 'Jump Server',
  'app.secondmenu.easeMiddleware.create.jumpServer': 'Add Jump Server',
  'app.secondmenu.easeMiddleware.update.jumpServer': 'Update Jump Server',
  'app.secondmenu.easeMiddleware.detail.jumpServer':
    'Jump Server BalanceDetail',

  'app.secondmenu.deploy': 'Deploy',
  'app.secondmenu.deploy.roles': 'Roles',
  'app.secondmenu.deploy.create.role': 'Create Role',
  'app.secondmenu.deploy.update.role': 'Update Role',
  'app.secondmenu.deployLogs': 'Logs',

  'app.secondmenu.manager': 'MANAGER',
  'app.secondmenu.manager.myworkspaces': 'My Workspaces',
  'app.secondmenu.workspace': 'WORKSPACE',
  'app.secondmenu.workspace.overview': 'Overview',
  'app.secondmenu.workspace.case': 'Case',
  'app.secondmenu.workspace.workflow': 'Workflow',
  'app.secondmenu.workspace.trafficmodel': 'Traffic Model',
  'app.secondmenu.workspace.task': 'Task',
  'app.secondmenu.workspace.analysis': 'Analysis',

  'app.secondmenu.tags': 'Tags',
  'app.secondmenu.tags.management': 'Management',
  'app.secondmenu.tags.resources': 'Resources',
  'app.secondmenu.tags.tag.resources': 'Tag Resources',
  'app.secondmenu.host': 'Host',

  'app.secondmenu.tenant': 'Tenant',
  'app.secondmenu.application.app': 'Apps',
  'app.secondmenu.security': 'Security',
  'app.secondmenu.manage': 'Manage',
  'app.secondmenu.doc': 'Doc',
  'app.secondmenu.services': 'Services',

  TenantGPUInfo: 'Tenant GPU Info',
  Statistic: 'Statistic',

  //Third Level Menu
  'app.thirdmenu.resources': 'Resources',
  'app.thirdmenu.dashboard': 'Dashboard',
  'app.thirdmenu.teams': 'Teams',
  'app.thirdmenu.team': 'Team',
  'app.thirdmenu.members': 'Members',
  'app.thirdmenu.sla': 'SLA',
  'app.thirdmenu.APIDocument': 'API Document',
  'app.thirdmenu.tracing': 'Tracing',
  'app.thirdmenu.stack': 'Stack',
  'app.thirdmenu.service': 'Service',
  'app.thirdmenu.services': 'Services',
  'app.thirdmenu.registry': 'Registry',
  'app.thirdmenu.registryMgmt': 'Registry',
  'app.thirdmenu.imageDetail': 'Image BalanceDetail',

  'app.thirdmenu.registries': 'Registries',
  'app.thirdmenu.http': 'HTTP',
  'app.thirdmenu.jvm': 'JVM',
  'app.thirdmenu.jdbc': 'JDBC',
  'app.thirdmenu.redis': 'Redis',
  'app.thirdmenu.kafka': 'Kafka',
  'app.thirdmenu.rabbitmq': 'RabbitMQ',
  'app.thirdmenu.gateway': 'Gateway',
  'app.thirdmenu.topnservice': 'Top N Service',
  'app.thirdmenu.topnservices': 'Top N Services',
  'app.thirdmenu.config.server': 'Config Server',
  'app.thirdmenu.config.servers': 'Config Server',
  'app.thirdmenu.configServerInventory': 'Config Server Inventory',
  'app.thirdmenu.createConfigServer': 'Create Config Server',
  'app.thirdmenu.editConfigServer': 'Edit Config Server',
  'app.thirdmenu.configuration': 'Configuration',
  'app.thirdmenu.configurations': 'Configurations',
  'app.thirdmenu.governance': 'Governance',
  'app.thirdmenu.governances': 'Governances',
  'app.thirdmenu.records': 'Records',
  'app.thirdmenu.mysql': 'MySQL',
  'app.thirdmenu.nginx': 'Nginx',
  'app.thirdmenu.infra': 'Infrastructure',
  'app.thirdmenu.k8s': 'Kubernetes',
  'app.thirdmenu.customized': 'Customized',
  'app.thirdmenu.hotevent': 'Hot Event',
  'app.thirdmenu.alertrule': 'Alert Rule',
  'app.thirdmenu.alertrule.addRule': 'Create Rule',
  'app.thirdmenu.alertrule.editRule': 'Edit Rule',
  'app.thirdmenu.alertlog': 'Alert Log',
  'app.thirdmenu.applog': 'Application',
  'app.thirdmenu.accesslog': 'Access',
  'app.thirdmenu.elasticSearch': 'ElasticSearch',
  'app.thirdmenu.cluster': 'Cluster',
  'app.thirdmenu.clusters': 'Clusters',
  'app.thirdmenu.trafficGate': 'Traffic Gate',
  'app.thirdmenu.trafficGates': 'Traffic Gates',
  'app.thirdmenu.trafficGate2': 'Traffic Gate2',
  'app.thirdmenu.pipeline': 'Pipeline',
  'app.thirdmenu.pipelines': 'Pipelines',
  'app.thirdmenu.pipeline2': 'Pipeline2',
  'app.thirdmenu.controller': 'Controller',
  'app.thirdmenu.controllers': 'Controllers',
  'app.thirdmenu.controller2': 'Controller2',
  'app.thirdmenu.filter': 'Filter',
  'app.thirdmenu.certificate': 'Certificate',
  'app.thirdmenu.deploy': 'Deploy',
  'app.thirdmenu.deploys': 'Deploys',
  'app.thirdmenu.server': 'Server',
  'app.thirdmenu.member': 'Member',
  'app.thirdmenu.canary': 'Canary',
  'app.thirdmenu.blueGreen': 'Blue-Green',
  'app.thirdmenu.function': 'Function',
  'app.thirdmenu.users': 'Users',
  'app.thirdmenu.auditLog': 'Audit Log',
  'app.thirdmenu.target': 'Target',
  'app.thirdmenu.NotificationCenter': 'Notification Center',
  'app.thirdmenu.NotificationCenterDetail': 'Detail',
  'app.thirdmenu.PushManagement': 'Push Management',
  'app.thirdmenu.PushLog': 'Push Log',

  'app.thirdmenu.RuleTemplate': 'Rule Template',
  'app.thirdmenu.RuleTemplateForm': 'Form',
  'app.thirdmenu.easeMiddleware.instance.clone': 'Clone',
  'app.thirdmenu.easeMiddleware.instance.configVersion': 'Config Version',

  'app.thirdmenu.stack.manifest': 'Stack Manifest',
  'app.thirdmenu.stack.dashboard.instance': 'Dashboard Instance',
  'app.thirdmenu.stack.create': 'Create Stack',
  'app.thirdmenu.stack.edit': 'Edit Stack',
  'app.thirdmenu.stack.version.differ': 'Stack Version Differ',
  'app.thirdmenu.stack.dashboard': 'Dashboard',
  'app.thirdmenu.stack.history.version': 'History Version',
  'app.thirdmenu.stack.rollback': 'Stack Rollback',
  'app.thirdmenu.tenant': 'Tenant',
  'app.thirdmenu.app.create': 'Create App',
  'app.thirdmenu.host.keyPair': 'Key Pair',
  'app.thirdmenu.host.keyPairs': 'Key Pairs',

  'app.thirdmenu.Service.Form': 'Service Form',
  'app.thirdmenu.Service.Instance': 'Service Instance',
  'app.thirdmenu.Service.Governance': 'Service Governance',
  'app.thirdmenu.Service.Observability': 'Service Observability',

  'app.thirdmenu.DataAccess': 'Data Access',
  'app.thirdmenu.AppAccess': 'App Access',
  'app.thirdmenu.AddGoAppData': 'Add Go App Data',

  'global.toolBar.service': 'Service',
  'global.toolBar.ZoneDomainService': 'Zone/Domain/Service',
  'global.toolBar.Component': 'Component',
  'global.toolBar.serviceInstance': 'Service Instances',
  'global.toolBar.chooseOneEaseStack': 'Ease Stack',
  'global.toolBar.StackCluster': 'Stack Cluster',
  'global.toolBar.number': 'Number',
  'global.toolBar.sortBy': 'Sort By',
  'global.toolBar.redisMethod': 'Redis Method',
  'global.toolBar.clusterName': 'Cluster Name',
  'global.toolBar.nodeName': 'Node Name',
  'global.toolBar.InstanceName': 'Instance Name',
  'global.toolBar.servicePrefix': 'Service Prefix',
  'global.toolBar.hostNamePrefix': 'HostName Prefix',
  'global.toolBar.timePicker': 'TIME PICKER',
  'global.toolBar.end': 'Ending At ',
  'global.toolBar.keywords': 'Keywords',
  'global.toolBar.podNamePrefix': 'Prefix',
  'global.toolBar.alertRuleName': 'Alert Rule Name',
  'global.toolBar.labels': 'Labels',
  'global.toolBar.statusCode': 'Status Code',
  'global.toolBar.name': 'Name',
  'global.toolBar.type': 'Type',
  'global.toolBar.zone': 'Zone',
  'global.toolBar.domain': 'Domain',

  'global.top.topHttpRequests': 'Top HTTP Requests',
  'global.top.topDatabaseRequests': 'Top Database Requests',
  'global.top.topRedisMethodRequests': 'Top Redis Method Requests',
  'global.top.topJVM': 'Top JVM',
  'global.top.topGateway': 'Top Gateway',
  'global.top.topExchangeRoutingKey': 'Top Exchange Routing Key',
  'global.top.topQueue': 'Top Queue',
  'global.top.topKafkaTopics': 'Top Kafka Topics',
  'global.top.topApi': 'TOP API',

  'global.consumer': 'Consumer',
  'global.producer': 'Producer',
  'global.relatedServices': 'Related Services',
  'global.noRelatedServices': 'No related services',
  'global.noData': 'No data are available.',
  'global.anotherService':
    'Please try selecting another service from the toolbar above.',
  'global.instances': 'Instances',
  'global.createService': 'Create Service',
  'global.config': 'Config',
  'global.binding': 'Binding',

  'global.table.message': 'Message',
  'global.table.service': 'Service',
  'global.table.host': 'Host',
  'global.table.times': 'Times',
  'global.table.RepeatCount': 'Repeat Count',
  'global.table.time': 'Time',
  'global.table.level': 'Level',
  'global.table.arrivalTime': 'Arrival Time',
  'global.table.latestTriggerTime': 'Latest Trigger Time',
  'global.table.nextTriggerTime': 'Next Trigger Time',
  'global.table.recoveryTime': 'Recovery Time',
  'global.table.details': 'Details',
  'global.table.date': 'Date',
  'global.table.status': 'Status',
  'global.table.traceId': 'TraceId',
  'global.table.url': 'Url',
  'global.table.logTime': 'Log Time',
  'global.table.stream': 'Stream',
  'global.table.tag': 'Tag',
  'global.table.name': 'Name',
  'global.table.port': 'Port',
  'global.table.operation': 'Operation',
  'global.table.value': 'Value',
  'global.table.instance': 'Instance',
  'global.table.type': 'Type',
  'global.table.description': 'Description',
  'global.table.author': 'Author',
  'global.table.actions': 'Actions',

  'global.newDashboard': 'New Dashboard',
  'global.editDashboard': 'Edit Dashboard',
  'global.deleteDashboard': 'Delete Dashboard',
  'global.newPanel': 'New Panel',
  'global.editPanel': 'Edit Panel',
  'global.deletePanel': 'Delete Panel',

  'global.charts': 'Charts',
  'global.confirmPassword': 'Confirm Password',
  'global.currentPassword': 'Current Password',
  'global.newPassword': 'New Password',
  'global.changePassword': 'Change Password',
  'global.auditLog': 'Audit Log',
  'global.userManager': 'User Manager',
  'global.logout': 'Logout',
  'global.dashboardName': 'Dashboard Name',
  'global.onlyOwnerVisible': 'Only owner visible',

  'global.delDesc':
    'This operation will delete the Dashboard and each Panel under the Dashboard. Do you want to continue?',
  'global.filterMetrics': 'Filter mertics by name',
  'global.PanelTitle': 'Panel Title',
  'global.ChartType': 'Chart Type',
  'global.serviceBinding': 'Service Binding',
  'global.groupBy': 'Group By',
  'global.selectdMetrics': 'Selectd Metrics',
  'global.delPanel': 'Are you sure you want to delete this panel?',
  'global.confirmText': 'Are you sure to delete current rule ?',

  'app.thirdmenu.Form': 'Form',
  'app.thirdmenu.editPipeline': 'Edit Pipeline',
  'app.thirdmenu.controllerDetail': 'Controller',
  'app.thirdmenu.history': 'History',
  'app.thirdmenu.historyDetail': 'History BalanceDetail',
  'app.thirdmenu.createService': 'Create Service',
  'app.thirdmenu.createRegistry': 'Create Registry',
  'app.thirdmenu.registryInventory': 'Registry Inventory',
  'app.thirdmenu.editRegistry': 'Edit Registry',
  'app.thirdmenu.serviceConfig': 'Service Config',
  'app.thirdmenu.serviceInstances': 'Service Instances',
  'app.thirdmenu.serviceConfigItems': 'Service Config Items',
  'app.thirdmenu.Zone': 'Zone',
  'app.thirdmenu.Zones': 'Zones',
  'app.thirdmenu.ClusterForm': 'Cluster Form',
  'app.thirdmenu.NamespaceForm': 'Namespace Form',
  'app.thirdmenu.ServiceForm': 'Service Form',
  'app.thirdmenu.Instances': 'Instances',
  'app.thirdmenu.Domain': 'Domain',
  'app.thirdmenu.Domains': 'Domains',
  'app.thirdmenu.TenantForm': 'Tenant Form',
  'app.thirdmenu.Ingress': 'Ingress',
  'app.thirdmenu.Ingresses': 'Ingresses',
  'app.thirdmenu.IngressForm': 'Ingress Form',
  'app.thirdmenu.TrafficControl': 'Traffic Control',
  'app.thirdmenu.TrafficControls': 'Traffic Controls',
  'app.thirdmenu.TrafficControlForm': 'Traffic Control Form',
  'app.thirdmenu.TrafficGroup': 'Traffic Group',
  'app.thirdmenu.TrafficGroupForm': 'Traffic Group Form',
  'app.thirdmenu.GlobalConfig': 'Global Config',
  'app.thirdmenu.CanaryReleases': 'Canary Releases',
  'app.thirdmenu.CanaryReleaseForm': 'Canary Release Form',
  'app.thirdmenu.ShadowService': 'Shadow Service',
  'app.thirdmenu.ShadowServices': 'Shadow Services',
  'app.thirdmenu.ShadowServiceForm': 'Shadow Service Form',

  'global.members': 'Members',

  'app.secondmenu.document': 'Document',
  'app.secondmenu.documents': 'Documents',
  'app.secondmenu.traceRecord': 'Trace Record',
  'app.secondmenu.traceRecords': 'Trace Records',
  'app.secondmenu.APM': 'APM',

  'app.secondmenu.accountSecurity': 'Account Security',
  'app.thirdmenu.accountSecurity.2fa': 'Two-factor Auth',

  'app.tenant.startTime': 'Start Time',
  'app.tenant.endTime': 'End Time',
  'app.tenant.keywords': 'Keywords',

  'global.attention': 'Attention',
  'app.secondmenu.store': 'Store',
  'app.secondmenu.plansAndUsage': 'Plans and Usage',
  ComparePlans: 'Compare Plans',
  'app.secondmenu.order': 'Orders',
  'app.secondmenu.plan': 'Plans',
  'app.secondmenu.quota': 'Quotas',
  'app.secondmenu.subscribe': 'Subscribes',
  'app.secondmenu.balance': 'Balances',
  'app.thirdmenu.detail': 'Details',
  'app.secondmenu.refund': 'Refund',

  'app.secondmenu.template': 'Template',
  'app.thirdmenu.createTemplate': 'Create Template',
  'app.thirdmenu.credentialManagement': 'Credential Management',
  'app.thirdmenu.Referral': 'Referral Program',
  'app.secondmenu.referral': 'Referral',
  'app.thirdmenu.management': 'Invitation',
  'app.thirdmenu.withdrawlMgmt': 'Withdrawl',

  Detail: 'Detail',
  Billing: 'Billing',
  Orders: 'Orders',
  Plan: 'Plan',
  Plans: 'Plans',
  Quotas: 'Quotas',
  Subscribes: 'Subscribes',
  Balances: 'Balances',
  Tenants: 'Tenants',
  Incomes: 'Incomes',
  Template: 'Template',

  'app.thirdmenu.rank': 'Rank',
  'app.secondmenu.start': 'Create a new GPU instance',
  'app.secondmenu.instance': 'Instance',

  'app.topmenu.cicd': 'CI/CD',
  'app.secondmenu.pipeline': 'Pipeline',
  'app.thirdmenu.myPipeline': 'My Pipeline',
  'app.thirdmenu.env': 'Env',
  'app.thirdmenu.credentials': 'Credentials',
  'app.thirdmenu.pipelineAuthorization': 'Pipeline authorization',
  'app.thirdmenu.notification': 'Notification',

  'app.secondmenu.system.management': 'Management',
  'app.thirdmenu.pipelineManagement': 'Pipeline',
  'app.thirdmenu.pipelineTemplateManagement': 'Pipeline Template',
  'app.thirdmenu.env.management': 'Env',
  'app.thirdmenu.pipelineEngine': 'Pipeline Engine',
  'app.thirdmenu.FAQ': 'FAQ',
  'app.thirdmenu.template.configs': 'Template Configs',
  'app.thirdmenu.container.images': 'Container Images',
  'app.thirdmenu.scripts': 'Scripts',
  'app.thirdmenu.container.images.management': 'Container Images',
  'app.secondmenu.system.global.settings': 'Global Settings',
  'app.thirdmenu.create.pipeline': 'Create Pipeline',
  'app.thirdmenu.edit.pipeline': 'Edit Pipeline',

  'app.secondmenu.gpu.limit': 'GPU Limit',
  'app.thirdmenu.inviter': 'Inviter',
  'app.thirdmenu.contactUs': 'Contact Us',
  'app.secondmenu.fapiao': 'Fapiao',
  'app.thirdmenu.fapiao': 'Fapiao',
  'app.thirdmenu.announcement': 'Announcement',
  'app.thirdmenu.newVersion': 'New Version',
};

const messageEn = {
  ...globalEn,
  ...baseEn,
  ...appEn,
  ...gatewayEn,
  ...serviceEn,
  ...stackEn,
  ...monitorEn,
  ...homeEn,
  ...middlewareEn,
  ...loadEn,
  ...tagsEn,
  ...tenantEn,
  ...settingsEn,
  ...operationEn,
  ...cicdEn,
};
export default messageEn;
